.text-center{ text-align: center; }


// .view-pc { 
//   display: block !important; 
//   @include sp{
//     display: none !important;
//   }
// }
// .view-sp { 
//     display: none !important; 
//   @include sp{
//     display: block !important;
//   }
// }
// .view-pc-inline { 
//   display:  inline !important; 
//   @include sp{
//     display: none !important;
//   }
// }
// .view-sp-inline { 
//     display: none !important; 
//   @include sp{
//     display:  inline !important;
//   }
// }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.pl10{ padding-left: 10px; }
.pl20{ padding-left: 20px; }
.pl30{ padding-left: 30px; }
.pl40{ padding-left: 40px; }
.pl50{ padding-left: 50px; }
.pr10{ padding-right: 10px; }
.pr20{ padding-right: 20px; }
.pr30{ padding-right: 30px; }
.pr40{ padding-right: 40px; }
.pr50{ padding-right: 50px; }

.w100{ width: 100%; }

.view-sp{
  display: block;
  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}
.view-pc{
  display: none;
  @media screen and (min-width: $breakpoint-md) {
    display: block;
  }
}

.btn{
  display: inline-block;
  border-radius: getVW-base(10);
  @media screen and (min-width: $breakpoint-md){
    border-radius: getPX-base(10);
  }
  &-white{
    text-align: center;
    font-size: (22/16 * 100%);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: getVW-base(72);
    background-color: $color-white;
    color: $color-main;
    &:visited{
      color: $color-main;
    }
    @media screen and (min-width: $breakpoint-md){
      line-height: getPX-base(72);
      font-size: getPX-base(22);
    }
  }
  &-orange{
    text-align: center;
    font-size: (22/16 * 100%);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: getVW-base(72);
    background-color: $color-main;
    color: $color-white;
    &:visited{
      color: $color-white;
    }
    @media screen and (min-width: $breakpoint-md){
      line-height: getPX-base(72);
      font-size: getPX-base(22);
    }
  }
}