//
// ブレイクポイント
// モバイルを基準 min-widthの指定
// px以上の
$breakpoint-xl: 1200px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;

//PCのときのモバイル画面サイズ
$main-width: 500px;
// デザイン上の横幅（比率計算で使用のためpx単位なし）
$main-base-width: 430;

//画像フォルダ
$img_path: "/img/";

//カラー設定
$color-main: #F89633;
$color-main-text: #173C50;
$color-white: #ffffff;
$color-green: #D6E5E3;
$color-gray: #CCCCCC;
$color-line: $color-main-text;

//z-index
$z-index-fix-btn-contact: 5;
$z-index-contact: 10;