@charset "UTF-8";


/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

.text-center {
  text-align: center;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pl50 {
  padding-left: 50px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.pr50 {
  padding-right: 50px;
}

.w100 {
  width: 100%;
}

.view-sp {
  display: block;
}

@media screen and (min-width: 768px) {
  .view-sp {
    display: none;
  }
}

.view-pc {
  display: none;
}

@media screen and (min-width: 768px) {
  .view-pc {
    display: block;
  }
}

.btn {
  display: inline-block;
  border-radius: calc(10 / 430 * 100vw);
}

@media screen and (min-width: 768px) {
  .btn {
    border-radius: calc(10 / 430 * 500px);
  }
}

.btn-white {
  text-align: center;
  font-size: 137.5%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: calc(72 / 430 * 100vw);
  background-color: #ffffff;
  color: #F89633;
}

.btn-white:visited {
  color: #F89633;
}

@media screen and (min-width: 768px) {
  .btn-white {
    line-height: calc(72 / 430 * 500px);
    font-size: calc(22 / 430 * 500px);
  }
}

.btn-orange {
  text-align: center;
  font-size: 137.5%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: calc(72 / 430 * 100vw);
  background-color: #F89633;
  color: #ffffff;
}

.btn-orange:visited {
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .btn-orange {
    line-height: calc(72 / 430 * 500px);
    font-size: calc(22 / 430 * 500px);
  }
}

html, body {
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 500;
  height: 100%;
  min-height: 100%;
  line-height: 1.6;
  font-size: calc(16 / 430 * 100vw);
  color: #173C50;
}

@media screen and (min-width: 768px) {
  html, body {
    font-size: calc(16 / 430 * 500px);
  }
}

a {
  transition: .2s ease;
  text-decoration: none;
  color: #173C50;
}

a:visited {
  color: #173C50;
}

.top-body {
  background-color: #CCC;
}

.bg-cover {
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-position: center center;
  opacity: 0;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  z-index: -1;
}

.bg-cover.show {
  opacity: 1;
}

.bg-img01 {
  background-image: url(/img/bg_01.jpg);
}

.bg-img02 {
  background-image: url(/img/bg_02.jpg);
}

.bg-img03 {
  background-image: url(/img/bg_03.jpg);
}

.main-wrapper {
  background-color: #ffffff;
  width: 100vw;
  height: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .main-wrapper {
    width: 500px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 992px) {
  .main-wrapper {
    width: 500px;
  }
}

.main-wrapper-mainform {
  width: 100vw;
  height: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 500px) {
  .main-wrapper-mainform {
    width: 500px;
    margin: 0 auto;
  }
}

.cts__innr-w {
  margin: 0 calc(15 / 430 * 100vw);
}

.cts__innr {
  margin: 0 calc(30 / 430 * 100vw);
}

@media screen and (min-width: 768px) {
  .cts__innr-w {
    margin: 0 calc(15 / 430 * 500px);
  }
  .cts__innr {
    margin: 0 calc(30 / 430 * 500px);
  }
}

.mv {
  height: 100%;
  background-color: #ffffff;
}

@media screen and (min-width: 768px) {
  .mv {
    position: relative;
  }
}

@media screen and (min-width: 768px) and (max-height: 1000px) {
  .mv {
    height: 1000px;
  }
}

.mv__ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(180 / 430 * 100vw);
  text-align: center;
}

.mv__ttl__innr h1 {
  font-size: 200%;
  vertical-align: middle;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mv__ttl__innr p {
  font-size: 106.25%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
  .mv__ttl {
    height: calc(180 / 430 * 500px);
  }
  .mv__ttl__innr h1 {
    font-size: calc(32 / 430 * 500px);
  }
  .mv__ttl__innr p {
    font-size: calc(17 / 430 * 500px);
  }
}

.mv__img {
  position: relative;
  background: url(../img/mv_bg.jpg) no-repeat top center;
  background-size: cover;
  height: calc(100% - (180/430 * 100vw) - (92/430 * 100vw));
  border-radius: calc(10 / 430 * 100vw);
}

.mv__img ul {
  position: absolute;
  left: 0;
  bottom: calc(10 / 430 * 100vw);
}

.mv__img ul li > div {
  display: inline-block;
  background-color: #ffffff;
  font-size: 181.25%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0 calc(10 / 430 * 100vw) calc(10 / 430 * 100vw) 0;
  padding: calc(20 / 430 * 100vw) calc(40 / 430 * 100vw) calc(20 / 430 * 100vw) calc(10 / 430 * 100vw);
  margin-bottom: calc(12 / 430 * 100vw);
}

@media screen and (min-width: 768px) {
  .mv__img {
    min-height: 600px;
    height: calc(100% - (180/430 * 500px) - (92/430 * 500px));
  }
  .mv__img ul {
    bottom: calc(10 / 430 * 500px);
  }
  .mv__img ul li > div {
    font-size: calc(29 / 430 * 500px);
    border-radius: 0 calc(10 / 430 * 500px) calc(10 / 430 * 500px) 0;
    padding: calc(20 / 430 * 500px) calc(40 / 430 * 500px) calc(20 / 430 * 500px) calc(10 / 430 * 500px);
    margin-bottom: calc(12 / 430 * 500px);
  }
}

.mv .fix-btn {
  width: 100%;
  padding: calc(10 / 430 * 100vw) 0;
  opacity: .9;
}

.mv .fix-btn.onfix {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
}

@media screen and (min-width: 768px) {
  .mv .fix-btn {
    position: absolute;
    bottom: 0;
    padding: calc(10 / 430 * 500px) 0;
  }
  .mv .fix-btn.onfix {
    width: 500px;
    left: 50%;
    margin-left: calc( -500px / 2);
  }
}

.lead {
  background-color: #ffffff;
  padding-bottom: calc(50 / 430 * 100vw);
}

.lead p {
  font-size: 150%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.79;
}

@media screen and (min-width: 768px) {
  .lead {
    padding-bottom: calc(50 / 430 * 500px);
  }
  .lead p {
    font-size: calc(24 / 430 * 500px);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.79;
  }
}

.contact {
  background-color: #F89633;
  padding: calc(40 / 430 * 100vw) 0 calc(30 / 430 * 100vw);
}

.contact p {
  font-size: 106.25%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  margin-bottom: calc(32 / 430 * 100vw);
}

@media screen and (min-width: 768px) {
  .contact {
    padding: calc(40 / 430 * 500px) 0 calc(30 / 430 * 500px);
  }
  .contact p {
    font-size: calc(17 / 430 * 500px);
    margin-bottom: calc(32 / 430 * 500px);
  }
}

.company {
  background-color: #ffffff;
  padding-top: calc(52 / 430 * 100vw);
}

.company__list__item {
  padding-bottom: calc(60 / 430 * 100vw);
}

.company__list__item__th {
  margin-bottom: calc(24 / 430 * 100vw);
}

.company__list__item__th img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.company__list__item__body h2 {
  margin-bottom: calc(13 / 430 * 100vw);
  font-size: 150%;
}

.company__list__item__body h2 span {
  font-size: 71%;
  display: block;
}

.company__list__item__body ul li {
  margin-bottom: calc(18 / 430 * 100vw);
  display: inline-block;
  font-size: 93.75%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: calc(30 / 430 * 100vw);
  border: 1px solid #173C50;
  padding: 0 calc(10 / 430 * 100vw);
}

@media screen and (min-width: 768px) {
  .company {
    padding-top: calc(52 / 430 * 500px);
  }
  .company__list__item {
    padding-bottom: calc(60 / 430 * 500px);
  }
  .company__list__item__th {
    margin-bottom: calc(24 / 430 * 500px);
  }
  .company__list__item__body h2 {
    margin-bottom: calc(13 / 430 * 500px);
    font-size: calc(24 / 430 * 500px);
  }
  .company__list__item__body ul li {
    margin-bottom: calc(18 / 430 * 500px);
    font-size: calc(15 / 430 * 500px);
    line-height: calc(30 / 430 * 500px);
    padding: 0 calc(10 / 430 * 500px);
  }
}

.relationship {
  padding: calc(60 / 430 * 100vw) 0;
  background-color: #D6E5E3;
}

.relationship__ttl {
  font-size: 150%;
  margin-bottom: calc(50 / 430 * 100vw);
}

.relationship__list__item {
  width: 100%;
  margin-bottom: calc(28 / 430 * 100vw);
  background-color: #ffffff;
  border-radius: calc(10 / 430 * 100vw);
}

.relationship__list__item:last-child {
  margin-bottom: 0;
}

.relationship__list__item__ttl {
  overflow: hidden;
  border-radius: calc(10 / 430 * 100vw) calc(10 / 430 * 100vw) 0 0;
}

.relationship__list__item__ttl img {
  width: 100%;
  height: calc(200 / 430 * 100vw);
  object-fit: contain;
  vertical-align: bottom;
}

.relationship__list__item__body {
  font-weight: bold;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7;
  font-size: 106.25%;
  padding: calc(22 / 430 * 100vw) 0;
}

@media screen and (min-width: 768px) {
  .relationship {
    padding: calc(60 / 430 * 500px) 0;
  }
  .relationship__ttl {
    font-size: calc(24 / 430 * 500px);
    margin-bottom: calc(50 / 430 * 500px);
  }
  .relationship__list__item {
    margin-bottom: calc(28 / 430 * 500px);
    border-radius: calc(10 / 430 * 500px);
  }
  .relationship__list__item__ttl {
    border-radius: calc(10 / 430 * 500px) calc(10 / 430 * 500px) 0 0;
  }
  .relationship__list__item__ttl img {
    height: calc(200 / 430 * 500px);
  }
  .relationship__list__item__body {
    font-size: calc(17 / 430 * 500px);
    padding: calc(22 / 430 * 500px) 0;
  }
}

.mainform-body {
  background-color: #D6E5E3;
  height: auto;
  min-height: auto;
}

.mainform {
  padding: calc(60 / 430 * 100vw) 0;
  background-color: #D6E5E3;
}

.mainform__innr {
  margin: 0 calc(40 / 430 * 100vw);
}

.mainform__ttl {
  font-size: 143.75%;
  margin-bottom: calc(40 / 430 * 100vw);
}

.mainform__dl__dt {
  display: flex;
  align-items: top;
  margin-bottom: calc(15 / 430 * 100vw);
}

.mainform__dl__dt__required {
  margin-top: calc(9 / 430 * 100vw);
}

.mainform__dl__dt__required span {
  display: block;
  color: #ffffff;
  background-color: #CCCCCC;
  font-size: 87.5%;
  padding: 0 calc(10 / 430 * 100vw);
  border-radius: calc(5 / 430 * 100vw);
}

.mainform__dl__dt__required span.on {
  background-color: #F89633;
}

.mainform__dl__dt__ttl {
  flex: 1;
  font-size: 150%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: calc(10 / 430 * 100vw);
}

.mainform__dl__dt__ttl span {
  font-size: 70%;
  font-weight: normal;
}

.mainform__dl__dd {
  margin-bottom: calc(30 / 430 * 100vw);
}

.mainform__dl__dd__innr-multi {
  display: flex;
  flex-wrap: wrap;
}

.mainform__dl__dd__2col {
  display: flex;
  flex-wrap: wrap;
  font-size: 106.25%;
}

.mainform__dl__dd__2col .checkbox {
  padding: 0 !important;
}

.mainform__dl__dd__2col__required {
  display: inline-block;
  color: #ffffff;
  background-color: #CCCCCC;
  font-size: 75%;
  padding: 0 calc(10 / 430 * 100vw);
  margin-right: calc(5 / 430 * 100vw);
  border-radius: calc(5 / 430 * 100vw);
}

.mainform__dl__dd__2col__required.on {
  background-color: #F89633;
}

.mainform__dl__dd a {
  text-decoration: underline;
}

.mainform__thanks__txt {
  font-size: 106.25%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: calc(30 / 430 * 100vw);
}

.mainform input, .mainform select, .mainform textarea {
  border: none;
  border-radius: calc(5 / 430 * 100vw);
}

.mainform input, .mainform textarea {
  font-size: 106.25%;
  width: 100%;
  margin-bottom: calc(20 / 430 * 100vw);
}

.mainform input::placeholder, .mainform textarea::placeholder {
  color: #CCCCCC;
}

.mainform textarea {
  width: 100%;
  height: calc(180 / 430 * 100vw);
  line-height: 1.8;
}

.mainform input[type="text"], .mainform input[type="email"], .mainform input[type="tel"] {
  line-height: calc(53 / 430 * 100vw);
  padding: 0 calc(20 / 430 * 100vw);
}

.mainform__submit {
  margin-top: calc(40 / 430 * 100vw);
}

.mainform__submit input {
  font-size: 137.5%;
}

.mainform .error span {
  color: #FF0000;
  font-size: calc(14/16 * 100%);
  border: 1px solid #FF0000;
  padding: 3px 8px;
  border-radius: 3px;
  background-color: #f6d2d2;
}

@media screen and (min-width: 768px) {
  .mainform {
    padding: calc(60 / 430 * 500px) 0;
  }
  .mainform__innr {
    margin: 0 calc(40 / 430 * 500px);
  }
  .mainform__ttl {
    font-size: calc(23 / 430 * 500px);
    margin-bottom: calc(50 / 430 * 500px);
  }
  .mainform__innr {
    margin: 0 calc(40 / 430 * 500px);
  }
  .mainform__ttl {
    font-size: 143.75%;
    margin-bottom: calc(40 / 430 * 500px);
  }
  .mainform__thanks__txt {
    margin-bottom: calc(30 / 430 * 500px);
  }
  .mainform__dl__dt {
    margin-bottom: calc(15 / 430 * 500px);
  }
  .mainform__dl__dt__required {
    margin-top: calc(9 / 430 * 500px);
  }
  .mainform__dl__dt__required span {
    padding: 0 calc(10 / 430 * 500px);
    border-radius: calc(5 / 430 * 500px);
  }
  .mainform__dl__dt__ttl {
    margin-left: calc(10 / 430 * 500px);
  }
  .mainform__dl__dd {
    margin-bottom: calc(30 / 430 * 500px);
  }
  .mainform__dl__dd__2col__required {
    padding: 0 calc(10 / 430 * 500px);
    margin-right: calc(5 / 430 * 500px);
    border-radius: calc(5 / 430 * 500px);
  }
  .mainform input, .mainform select, .mainform textarea {
    border-radius: calc(5 / 430 * 500px);
  }
  .mainform input, .mainform textarea {
    margin-bottom: calc(20 / 430 * 500px);
  }
  .mainform textarea {
    height: calc(180 / 430 * 500px);
    line-height: 1.8;
  }
  .mainform input[type="text"], .mainform input[type="email"], .mainform input[type="tel"] {
    line-height: calc(53 / 430 * 500px);
    padding: 0 calc(20 / 430 * 500px);
  }
  .mainform__submit {
    margin-top: calc(40 / 430 * 500px);
  }
}

.mainform .radio {
  font-size: 106.25%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: calc(20 / 430 * 100vw);
}

.mainform .radio.radio-inline {
  display: inline-block;
}

.mainform .radio input[type="radio"] {
  display: none;
}

.mainform .radio input[type="radio"]:checked + label:before {
  border-color: #ffffff;
}

.mainform .radio input[type="radio"]:checked + label:after {
  transform: scale(1);
}

.mainform .radio label {
  display: inline-block;
  min-height: 22px;
  position: relative;
  padding: 0 calc(10 / 430 * 100vw) 0 27px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
}

.mainform .radio label:before, .mainform .radio label:after {
  position: absolute;
  content: '';
  border-radius: 50%;
  transition: all .3s ease;
  transition-property: transform, border-color;
}

.mainform .radio label:before {
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background-color: #ffffff;
  border: 2px solid #ffffff;
}

.mainform .radio label:after {
  top: 6px;
  left: 6px;
  width: 10px;
  height: 10px;
  transform: scale(0);
  background: #173C50;
}

@media screen and (min-width: 768px) {
  .mainform .radio {
    margin-bottom: calc(20 / 430 * 500px);
  }
  .mainform .radio.radio-inline {
    display: inline-block;
  }
  .mainform .radio input[type="radio"] {
    display: none;
  }
  .mainform .radio input[type="radio"]:checked + label:before {
    border-color: #ffffff;
  }
  .mainform .radio input[type="radio"]:checked + label:after {
    transform: scale(1);
  }
  .mainform .radio label {
    padding: 0 calc(10 / 430 * 500px) 0 27px;
  }
  .mainform .radio label:before {
    left: 0;
    top: 5px;
  }
  .mainform .radio label:after {
    top: 11px;
    left: 6px;
  }
}

.mainform .checkbox {
  padding: 0 calc(20 / 430 * 100vw) calc(20 / 430 * 100vw) 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mainform .checkbox-Input {
  margin: 0;
  width: 0;
  opacity: 0;
}

@media screen and (min-width: 768px) {
  .mainform .checkbox {
    padding: 0 calc(20 / 430 * 500px) calc(20 / 430 * 500px) 0;
  }
}

.mainform .checkbox:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

.mainform .checkbox-Input:checked + .checkbox-DummyInput {
  background: #ffffff;
}

.mainform .checkbox-Input:checked + .checkbox-DummyInput::before {
  content: "";
  display: block;
  position: absolute;
  top: 30%;
  left: 65%;
  width: 50%;
  height: 4px;
  transform: translate(-6px, 5px) rotateZ(-135deg);
  transform-origin: 2px 2px;
  background: #173C50;
}

.mainform .checkbox-Input:checked + .checkbox-DummyInput::after {
  content: "";
  display: block;
  position: absolute;
  top: 30%;
  left: 65%;
  width: 76%;
  height: 4px;
  transform: translate(-6px, 5px) rotateZ(-45deg);
  transform-origin: 2px 2px;
  background: #173C50;
}

.mainform .checkbox-DummyInput {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 22px;
  height: 22px;
  border: solid 2px transparent;
  background: #ffffff;
}

.mainform .checkbox-LabelText {
  margin-left: 12px;
  display: block;
  font-size: 106.25%;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer {
  background-color: #ffffff;
  padding: calc(60 / 430 * 100vw) 0 calc(120 / 430 * 100vw);
}

.footer__operation {
  margin-bottom: calc(48 / 430 * 100vw);
}

.footer__operation__ttl {
  text-align: center;
  margin-bottom: calc(20 / 430 * 100vw);
}

.footer__operation__logo {
  margin-bottom: calc(24 / 430 * 100vw);
  text-align: center;
}

.footer__operation__logo img {
  width: calc(72 / 430 * 100vw);
}

.footer__operation__name {
  text-align: center;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: calc(24 / 430 * 100vw);
}

@media screen and (min-width: 768px) {
  .footer {
    padding: calc(60 / 430 * 500px) 0 calc(120 / 430 * 500px);
  }
  .footer__operation {
    margin-bottom: calc(48 / 430 * 500px);
  }
  .footer__operation__ttl {
    margin-bottom: calc(20 / 430 * 500px);
  }
  .footer__operation__logo {
    margin-bottom: calc(24 / 430 * 500px);
  }
  .footer__operation__logo img {
    width: calc(72 / 430 * 500px);
  }
  .footer__operation__name {
    margin-bottom: calc(24 / 430 * 500px);
  }
}

@media screen and (min-width: 768px) {
  .modaal-wrapper .modaal-close {
    border-radius: 5px;
    background: #ffffff;
    left: 50%;
    top: 6vh;
    margin-left: 190px;
  }
  .modaal-wrapper .modaal-container {
    height: 90vh !important;
  }
  .modaal-wrapper .modaal-inner-wrapper {
    padding: 0 !important;
  }
  .modaal-wrapper .modaal-close:after, .modaal-wrapper .modaal-close:before {
    top: 7px;
    left: 25px;
    width: 1px;
    height: 36px;
    border-radius: 0;
    background: #173C50;
  }
}

.modaal-fullscreen .modaal-close {
  border-radius: 5px;
  background: #ffffff;
  right: calc(20 / 430 * 100vw);
  top: calc(20 / 430 * 100vw);
}

.modaal-fullscreen .modaal-close:after, .modaal-fullscreen .modaal-close:before {
  display: block;
  content: " ";
  position: absolute;
  top: 7px;
  left: 25px;
  width: 1px;
  height: 36px;
  border-radius: 0;
  background: #173C50;
  -webkit-transition: background .2s ease-in-out;
  transition: background .2s ease-in-out;
}
