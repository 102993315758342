@import 'settings';
@import 'mixin';
@import 'reset';
@import 'module';

html,body{
  font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "メイリオ", "Hiragino Sans", sans-serif;
  font-weight: 500;
  height: 100%;
  min-height: 100%;
  line-height: 1.6;
  //letter-spacing: 0.02em;
  font-size: getVW-base(16);
  color: $color-main-text;
  @media screen and (min-width: $breakpoint-md) {
    font-size: getPX-base(16);
  }
}
a {
  transition: .2s ease;
  text-decoration: none;
  color: $color-main-text;
  &:visited{
    color: $color-main-text;
  }
}
.top-body{
  background-color: #CCC;
}
.bg{
  &-cover{
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-position: center center;
    opacity: 0;
  -webkit-background-size: cover;
        background-size: cover;
  -webkit-transition: all 1s ease 0s;
   -moz-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
    z-index: -1;
    &.show{
      opacity: 1;
    }
  }
  &-img01{
    background-image: url(/img/bg_01.jpg);
  }
  &-img02{
    background-image: url(/img/bg_02.jpg);
  }
  &-img03{
    background-image: url(/img/bg_03.jpg);
  }
}
.main-wrapper{
  background-color: $color-white;
  width: 100vw;
  height: 100%;
  margin:  0 auto;
  @media screen and (min-width: $breakpoint-md) {
    width: $main-width;
    margin:  0 auto;
  }
  @media screen and (min-width: $breakpoint-lg) {
    width: $main-width;

  }
}
.main-wrapper-mainform{
  width: 100vw;
  height: 100%;
  margin:  0 auto;
  // @media screen and (min-width: $breakpoint-md) {
  //   width: $main-width;
  //   margin:  0 auto;
  // }
  // @media screen and (min-width: $breakpoint-lg) {
  //   width: $main-width;
  // }
    @media screen and (min-width: 500px) {
    width: $main-width;
    margin:  0 auto;
  }
}
.cts{
  &__innr-w{
    margin: 0 getVW-base(15);
  }
  &__innr{
    margin: 0 getVW-base(30);
  }
  @media screen and (min-width: $breakpoint-md) {
    &__innr-w{
      margin: 0 getPX-base(15);
    }
    &__innr{
      margin: 0 getPX-base(30);
    }
  }
}
.mv{
  height: 100%;
  background-color: $color-white;
  @media screen and (min-width: $breakpoint-md){
    //height: auto;
    //padding-bottom: getPX-base(96);
    position: relative;
  }
  @media screen and (min-width: $breakpoint-md) and (max-height: 1000px) {
    height: 1000px;
  }
  &__ttl{
    display: flex;
    align-items: center;
    justify-content:center;
    height: getVW-base(180);
    text-align: center;
    &__innr{
      h1{
        font-size: (32/16 * 100%);
        vertical-align: middle;
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
      }
      p{
        font-size: (17/16 * 100%);
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    @media screen and (min-width: $breakpoint-md){
      height: getPX-base(180);
      &__innr{
        h1{
          font-size: getPX-base(32);
        }
        p{
          font-size: getPX-base(17);
        }
      }
    }
  }
  &__img{
    position: relative;
    background: url(../img/mv_bg.jpg) no-repeat top center;
    background-size: cover;
    height: calc(100% - (180/#{$main-base-width} * 100vw) - (92/#{$main-base-width} * 100vw) );
    border-radius: getVW-base(10);
    ul{
      position: absolute;
      left: 0;
      bottom: getVW-base(10);
      li{
        & > div{
          display: inline-block;
          background-color: $color-white;
          font-size: (29/16 * 100%);
          font-weight: bold;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          border-radius: 0 getVW-base(10) getVW-base(10) 0;
          padding: getVW-base(20) getVW-base(40) getVW-base(20) getVW-base(10); 
          margin-bottom: getVW-base(12);
        }
      }
    }
    @media screen and (min-width: $breakpoint-md){
      min-height: 600px;
      height: calc(100% - (180/#{$main-base-width} * #{$main-width}) - (92/#{$main-base-width} * #{$main-width}) );
      ul{
        bottom: getPX-base(10);
        li{
          & > div{
            font-size: getPX-base(29);
            border-radius: 0 getPX-base(10) getPX-base(10) 0;
            padding: getPX-base(20) getPX-base(40) getPX-base(20) getPX-base(10); 
            margin-bottom: getPX-base(12);
          }
        }
      }
    }
  }
  .fix-btn{
    width: 100%;
    padding: getVW-base(10) 0;
    opacity: .9;
    &.onfix{
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: $z-index-fix-btn-contact;
    }
    @media screen and (min-width: $breakpoint-md){
      position: absolute;
      bottom: 0;
      padding: getPX-base(10) 0;
      &.onfix{
        width: $main-width;
        left: 50%;
        margin-left: calc( -#{$main-width} / 2);
      }
    }
  }
}
.lead{
  background-color: $color-white;
  padding-bottom: getVW-base(50);
  p{
    font-size: (24/16 * 100%);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.79;
  }
  @media screen and (min-width: $breakpoint-md){
    padding-bottom: getPX-base(50);
    p{
      font-size: getPX-base(24);
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1.79;
    }
  }
}
.contact{
  background-color: $color-main;
  padding: getVW-base(40) 0 getVW-base(30);
  p{
    font-size: (17/16 * 100%);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-white;
    margin-bottom: getVW-base(32);
  }
  @media screen and (min-width: $breakpoint-md){
    padding: getPX-base(40) 0 getPX-base(30);
    p{
      font-size: getPX-base(17);
      margin-bottom: getPX-base(32);
    }
  }
}
.company{
  background-color: $color-white;
  padding-top: getVW-base(52);
  &__list{
    &__item{
      padding-bottom: getVW-base(60);
      &__th{
        margin-bottom: getVW-base(24);
        img{
          width: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
      &__body{
        h2{
          margin-bottom: getVW-base(13);
          font-size: (24/16 * 100%);
          span{
            font-size: 71%;
            display: block;
          }
        }
        ul{
          li{
            margin-bottom: getVW-base(18);
            display: inline-block;
            font-size: (15/16 * 100%);
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: getVW-base(30);
            border: 1px solid $color-main-text;
            padding: 0 getVW-base(10);
          }
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-md){
    padding-top: getPX-base(52);
    &__list{
      &__item{
        padding-bottom: getPX-base(60);
        &__th{
          margin-bottom: getPX-base(24);
        }
        &__body{
          h2{
            margin-bottom: getPX-base(13);
            font-size: getPX-base(24);
          }
          ul{
            li{
              margin-bottom: getPX-base(18);
              font-size: getPX-base(15);
              line-height: getPX-base(30);
              padding: 0 getPX-base(10);
            }
          }
        }
      }
    }   
  }
}
.relationship{
  padding: getVW-base(60) 0;
  background-color: $color-green;
  &__ttl{
    font-size: (24/16 * 100%);
    margin-bottom: getVW-base(50);
  }
  &__list{
    &__item{
      width: 100%;
      margin-bottom: getVW-base(28);
      background-color: $color-white;
      border-radius: getVW-base(10);
      &:last-child{
        margin-bottom: 0;
      }
      &__ttl{
        overflow: hidden;
        border-radius: getVW-base(10) getVW-base(10) 0 0;
        img{
          width: 100%;
          height: getVW-base(200);
          object-fit: contain;
          vertical-align: bottom;
        }
      }
      &__body{
        font-weight: bold;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1.7;
        font-size: (17/16 * 100%);
        padding: getVW-base(22) 0;
      }
    }
  }
  @media screen and (min-width: $breakpoint-md){
    padding: getPX-base(60) 0;
    &__ttl{
      font-size: getPX-base(24);
      margin-bottom: getPX-base(50);
    }
    &__list{
      &__item{
        margin-bottom: getPX-base(28);
        border-radius: getPX-base(10);
        &__ttl{
          border-radius: getPX-base(10) getPX-base(10) 0 0;
          img{
            height: getPX-base(200);
          }
        }
        &__body{
          font-size: getPX-base(17);
          padding: getPX-base(22) 0;
        }
      }
    }
  }
}
.mainform-body{
  background-color: $color-green;
  height: auto;
  min-height: auto;
}
.mainform{
  padding: getVW-base(60) 0;
  background-color: $color-green;
  &__innr{
    margin: 0 getVW-base(40);
  }
  &__ttl{
    font-size: (23/16 * 100%);
    margin-bottom: getVW-base(40);
  }
  &__dl{
    &__dt{
      display: flex;
      align-items: top;
      margin-bottom: getVW-base(15);
      &__required{
        margin-top: getVW-base(9);
        span{
          display: block;
          color: $color-white;
          background-color: $color-gray;
          font-size: (14/16 * 100%);
          padding: 0 getVW-base(10);
          border-radius: getVW-base(5);
          &.on{
            background-color: $color-main;
          }
        }
      }
      &__ttl{
        flex: 1;
        font-size: (24/16 * 100%);
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-left: getVW-base(10);
        span{
          font-size: 70%;
          font-weight: normal;
        }
      }
    }
    &__dd{
      margin-bottom: getVW-base(30);
      &__innr-multi{
        display: flex;
        flex-wrap: wrap;
      }
      &__2col{
        display: flex;
        flex-wrap: wrap;
        font-size: (17/16 * 100%);
        .checkbox{
          padding: 0 !important;
        }
        &__required{
          display: inline-block;
          color: $color-white;
          background-color: $color-gray;
          font-size: (12/16 * 100%);
          padding: 0 getVW-base(10);
          margin-right: getVW-base(5);
          border-radius: getVW-base(5);
          &.on{
            background-color: $color-main;
          }
        }
      }
      a{
        text-decoration: underline;
      }
    }
  }
  &__thanks{
    &__txt{
      font-size: (17/16 * 100%);
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-bottom: getVW-base(30);
    }
  }
  input, select, textarea{
    border: none;
    border-radius: getVW-base(5);
  }
  input, textarea{
    font-size: (17/16 * 100%);
    width: 100%;
    margin-bottom: getVW-base(20);
    &::placeholder {
      color: $color-gray;
    }
  }
  textarea{
    width: 100%;
    height: getVW-base(180);
    line-height: 1.8;
  }
  input[type="text"] ,input[type="email"] ,input[type="tel"]{
    line-height: getVW-base(53);
    padding: 0 getVW-base(20);
  }
  &__submit{
    margin-top: getVW-base(40);
    input{
      font-size: (22/16 * 100%);
    }
  }
  .error{
    span{
      color: #FF0000;
      font-size: calc(14/16 * 100%);
      border: 1px solid #FF0000;
      padding: 3px 8px;
      border-radius: 3px;
      background-color: #f6d2d2;
    }
  }
  @media screen and (min-width: $breakpoint-md){
    padding: getPX-base(60) 0;
    &__innr{
      margin: 0 getPX-base(40);
    }
    &__ttl{
      font-size: getPX-base(23);
      margin-bottom: getPX-base(50);
    }
    &__innr{
      margin: 0 getPX-base(40);
    }
    &__ttl{
      font-size: (23/16 * 100%);
      margin-bottom: getPX-base(40);
    }
    &__thanks{
      &__txt{
        margin-bottom: getPX-base(30);
      }
    }
    &__dl{
      &__dt{
        margin-bottom: getPX-base(15);
        &__required{
          margin-top: getPX-base(9);
          span{
            padding: 0 getPX-base(10);
            border-radius: getPX-base(5);
          }
        }
        &__ttl{
          margin-left: getPX-base(10);
        }
      }
      &__dd{
        margin-bottom: getPX-base(30);
        &__2col{
          &__required{
            padding: 0 getPX-base(10);
            margin-right: getPX-base(5);
            border-radius: getPX-base(5);
          }
        }
      }
    }
    input, select, textarea{
      border-radius: getPX-base(5);
    }
    input, textarea{
      margin-bottom: getPX-base(20);
    }
    textarea{
      height: getPX-base(180);
      line-height: 1.8;
    }
    input[type="text"] ,input[type="email"] ,input[type="tel"]{
      line-height: getPX-base(53);
      padding: 0 getPX-base(20);
    }
    &__submit{
      margin-top: getPX-base(40);
    }
  }

  $radio-checked-color: $color-main-text;
  $radio-border-color: $color-white;
  $radio-size: 22px;
  $radio-checked-size: 10px; 

  .radio {
    font-size: (17/16 * 100%);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: getVW-base(20);

    &.radio-inline {
        display: inline-block;
    }

    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $color-white;
        }
        &:checked + label:after {
            transform: scale(1);
        }
    }
    
    label {
      display: inline-block;
      min-height: $radio-size;
      position: relative;
      padding: 0 getVW-base(10) 0 ($radio-size + 5px);
      margin-bottom: 0;
      cursor: pointer;
      vertical-align: bottom;
      &:before, &:after {
          position: absolute;            
          content: '';  
          border-radius: 50%;
          transition: all .3s ease;
          transition-property: transform, border-color;
      }
      &:before {
          left: 0;
          top: 0;
          width: $radio-size;
          height: $radio-size;
          background-color: $color-white;
          border: 2px solid $radio-border-color;
      }
      &:after {
          top: $radio-size / 2 - $radio-checked-size / 2;
          left: $radio-size / 2 - $radio-checked-size / 2;
          width:$radio-checked-size;
          height:$radio-checked-size;
          transform: scale(0);
          background:$radio-checked-color;
      }
    }
    @media screen and (min-width: $breakpoint-md){
      margin-bottom: getPX-base(20);
      &.radio-inline {
        display: inline-block;
      }
      input[type="radio"] {
        display: none;
        &:checked + label:before {
          border-color: $color-white;
        }
        &:checked + label:after {
          transform: scale(1);
        }
      }
      label {
        padding: 0 getPX-base(10) 0 ($radio-size + 5px);
        &:before {
          left: 0;
          top: 5px;
        }
        &:after {
            top: $radio-size / 2 - $radio-checked-size / 2 + 5px;
            left: $radio-size / 2 - $radio-checked-size / 2;
        }
      }
    }
  }

  .checkbox {
    padding: 0 getVW-base(20) getVW-base(20) 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-Input {
      margin: 0;
      width: 0;
      opacity: 0;
    }
    @media screen and (min-width: $breakpoint-md){
      padding: 0 getPX-base(20) getPX-base(20) 0;
    }
  }
  .checkbox:hover{
    background: rgba(0,0,0,.05) !important;
  }
  .checkbox-Input:focus + .checkbox-DummyInput{
    //border: dashed 2px #333333;
  }
  .checkbox-Input:checked + .checkbox-DummyInput {
    background: $color-white;
  }
  .checkbox-Input:checked + .checkbox-DummyInput::before {
    content: "";
    display: block;
    position: absolute;
    top: 30%;
    left: 65%;
    width: 50%;
    height: 4px;
    transform: translate(-6px, 5px) rotateZ(-135deg);
    transform-origin: 2px 2px;
    background: $color-main-text;
  }
  .checkbox-Input:checked + .checkbox-DummyInput::after {
    content: "";
    display: block;
    position: absolute;
    top: 30%;
    left: 65%;
    width: 76%;
    height: 4px;
    transform: translate(-6px, 5px) rotateZ(-45deg);
    transform-origin: 2px 2px;
    background: $color-main-text;
  }
  .checkbox-DummyInput {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 22px;
    height: 22px;
    border: solid 2px transparent;
    background: $color-white;
  }
  .checkbox-LabelText {
    margin-left: 12px;
    display: block;
    font-size: (17/16 * 100%);
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}
.footer{
  background-color: $color-white;
  padding: getVW-base(60) 0 getVW-base(120);
  &__operation{
    margin-bottom: getVW-base(48);
    &__ttl{
      text-align: center;
      margin-bottom: getVW-base(20);
    }
    &__logo{
      margin-bottom: getVW-base(24);
      text-align: center;
      img{
        width: getVW-base(72);
      }
    }
    &__name{
      text-align: center;
      font-weight: bold;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-bottom: getVW-base(24);
    }
    &__txt{
      
    }
    // @media screen and (min-width: $breakpoint-md) {
    //   padding: getPX-base(100);
    //   &__logo{
    //     margin: getPX-base(70) 0;
    //   }
    //   &__name{
    //     margin-bottom: getPX-base(70);
    //   }
    // }
  }
  @media screen and (min-width: $breakpoint-md) {
    padding: getPX-base(60) 0 getPX-base(120);
    &__operation{
      margin-bottom: getPX-base(48);
      &__ttl{
        margin-bottom: getPX-base(20);
      }
      &__logo{
        margin-bottom: getPX-base(24);
        img{
          width: getPX-base(72);
        }
      }
      &__name{
        margin-bottom: getPX-base(24);
      }
      &__txt{
        
      }
    }
  }
}


.modaal-wrapper{
  @media screen and (min-width: $breakpoint-md) {
    .modaal-close {
      border-radius: 5px;
      background: $color-white;
      left: 50%;
      top: 6vh;
      margin-left: 190px;
    }
    .modaal-container{
      height: 90vh !important;
    }
    .modaal-inner-wrapper {
      padding: 0 !important;
    }
    .modaal-close:after, .modaal-close:before {
      top: 7px;
      left: 25px;
      width: 1px;
      height: 36px;
      border-radius: 0;
      background: $color-main-text;
    }
  }
}
.modaal-fullscreen{
  .modaal-close {
    border-radius: 5px;
    background: $color-white;
    right: getVW-base(20);
    top: getVW-base(20);
  }
  .modaal-close:after, .modaal-close:before {
      display: block;
      content: " ";
      position: absolute;
      top: 7px;
      left: 25px;
      width: 1px;
      height: 36px;
      border-radius: 0;
      background: $color-main-text;
      -webkit-transition: background .2s ease-in-out;
      transition: background .2s ease-in-out;
  }
}
