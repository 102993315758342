@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// pxから%を計算して返す
@function getPercent($target-px, $wrap-px) {
  @return $target-px / $wrap-px * 100%;
}
// pxから%(VW)を計算して返す
@function getVW($target-px, $wrap-px) {
  @return $target-px / $wrap-px * 100vw;
}
// pxから%(hw)を計算して返す
@function gethW($target-px, $wrap-px) {
  @return $target-px / $wrap-px * 100hw;
}


// デザイン上の横幅を元に計算
@function getVW-base($target-px) {
  @return calc(#{$target-px} / #{$main-base-width} * 100vw);
}
// デザイン上の横幅を元に計算してpx
@function getPX-base($target-px) {
  @return calc(#{$target-px} / #{$main-base-width} * #{$main-width});
}
// デザイン上の横幅を元に計算した%
@function getPercent-base($target-px) {
  @return calc(#{$target-px} / #{$main-base-width} * 100%;
}

// 
@function getPX-base($target-px) {
  @return calc(#{$target-px} / #{$main-base-width} * #{$main-width});
}